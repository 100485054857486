/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import HeaderDesktop from "./HeaderDesktop"
import "./layout.css"
import HeaderMobile from "./HeaderMobile"
import useWindowDimensions from "../utilities/WindowDimensions"
import { useEffect } from "react"
import Footer from "./global/Footer"

const Layout = ({ children }) => {
  const { width } = useWindowDimensions()

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const headerOutput = () => {
    if (width > 720) {
      return (
        <HeaderDesktop siteTitle={data.site.siteMetadata?.title || `Title`} />
      )
    } else if (width < 720) {
      return (
        <HeaderMobile siteTitle={data.site.siteMetadata?.title || `Title`} />
      )
    }
  }

  useEffect(() => {
    headerOutput()
  }, [width])
  return (
    <>
      {width ? headerOutput() : null}
      <div
        style={{
          position: "relative",
          margin: `0 auto`,
          coloe: "white",
        }}
      >
        <main>{children}</main>

        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
