import React from "react"
import { Link } from "gatsby"

function NavMenuItemsDesktop() {
  return (
    <>
      <Link to="/branding">Branding</Link>
      <Link to="/development">Development</Link>
      <Link to="/hosting">Hosting</Link>
      <Link to="/freelance">Freelance</Link>
      <Link to="/contact">Contact</Link>
    </>
  )
}

export default NavMenuItemsDesktop
