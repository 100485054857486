import * as React from "react"
import PropTypes from "prop-types"

import { Link } from "gatsby"
import { useState } from "react"
import NavMenuItemsMobile from "./home/nav-bar/NavMenuItemsMobile"

export default function HeaderMobile({ siteTitle }) {
  const [menuOpen, setMenuOpen] = useState(false)

  const NavOpen = (
    <>
      <NavMenuItemsMobile />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="0.333333in"
        height="0.333333in"
        viewBox="0 0 30 30"
        onClick={() => {
          setMenuOpen(!menuOpen)
        }}
      >
        <path
          id="Selection"
          fill="red"
          stroke="black"
          stroke-width="1"
          d="M 3.15,1.13
           C 9.97,-0.01 9.47,5.27 14.07,6.40
             15.58,6.77 16.49,6.29 17.72,5.48
             19.23,4.47 21.39,1.50 24.01,0.88
             27.56,0.05 30.92,3.48 28.25,7.91
             26.85,10.24 23.48,12.25 23.48,15.00
             23.48,18.37 34.17,25.44 26.85,28.87
             22.33,30.99 19.14,24.39 15.93,23.60
             14.42,23.23 13.51,23.71 12.28,24.52
             10.77,25.53 8.61,28.50 5.99,29.12
             2.44,29.95 -0.92,26.52 1.75,22.09
             3.15,19.76 6.52,17.75 6.52,15.00
             6.52,9.73 -4.03,7.29 3.15,1.13 Z"
        />
      </svg>
    </>
  )

  const NavClosed = (
    <svg
      viewBox="0 0 100 80"
      width="40"
      height="40"
      fill="white"
      onClick={() => {
        setMenuOpen(!menuOpen)
      }}
    >
      <rect width="100" height="10"></rect>
      <rect y="30" width="100" height="10"></rect>
      <rect y="60" width="100" height="10"></rect>
    </svg>
  )

  return (
    <header className="header-mobile">
      <Link to="/" className="logo-contai">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 300 150"
          enableBackground="new 0 0 300 150"
          width="100"
        >
          <path
            d="M207.4,84.2c5,2.3,10.2,4.5,15.5,6.3c5,1.7,10,3,15.3,3.8c2.2,0.3,4.4,0.4,6.5,0.6c0.7,0.1,1-0.1,1.3-0.7
      c1.9-4.5,3.1-9,3.6-13.8c0.5-4.7,0.4-9.4-0.2-14.2c-0.6-4.8-1.8-9.5-3.4-14c-0.2-0.4-0.6-0.8-0.5-1.3c0.1-0.3-0.2-0.1-0.3-0.1
      c-10.1,1.3-19.8,3.7-29.2,7.3c-14.2,5.5-27.1,12.8-40,20.3c-11.8,6.8-23.3,14.2-35.3,20.7c-15.1,8.2-30.8,15.3-47.4,21
      c-3.9,1.3-8.1,2.1-12.2,2.9c-4.1,0.8-8.2,1.3-12.4,1.7c-2.7,0.2-5.4,0.2-8,0.4c-0.6,0-0.8-0.2-1-0.7c-2-5.2-3.6-10.5-5-15.8
      c-1-3.8-1.8-7.5-2.5-11.3c-0.5-2.5-0.7-5.1-1.1-7.6c-0.5-3.4-0.8-6.7-1-10.1C50,76.2,50,72.9,50,69.5c0.1-3.2,0.2-6.4,0.5-9.5
      c1.2-11.9,4.1-23.4,9.2-34.5c0.2-0.4,0.4-0.5,0.9-0.5c4.3,0,8.5,0.4,12.8,0.9c18.6,2.3,35.7,8.4,52.2,16.1
      c5.7,2.6,11.2,5.6,16.8,8.6L165,62.2"
            strokeWidth="12"
            stroke="#FFFFFF"
          />
        </svg>
      </Link>
      {menuOpen ? NavOpen : NavClosed}
    </header>
  )
}
HeaderMobile.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderMobile.defaultProps = {
  siteTitle: ``,
}
