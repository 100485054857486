import { useState, useEffect } from "react"

function getWindowDimensions() {
  let windowGlobal = typeof window !== "undefined" && window

  const { innerWidth: width, innerHeight: height } = windowGlobal
  return {
    width,
    height,
  }
}

export default function useWindowDimensions() {
  let windowGlobal = typeof window !== "undefined" && window
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    windowGlobal.addEventListener("resize", handleResize)
    return () => windowGlobal.removeEventListener("resize", handleResize)
  }, [windowGlobal])

  return windowDimensions
}
