import React from "react"
export default function Footer() {
  return (
    <footer>
      <a href="/privacy-policy">Privacy Policy</a>
      <a href="https://uk.linkedin.com/in/danielroberts1980">
        © {new Date().getFullYear()} - Daniel Roberts
      </a>
    </footer>
  )
}
